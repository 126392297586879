<template>
    <v-bottom-navigation fixed class="d-flex justify-space-between align-center">
        <div class="d-flex" v-if="$vuetify.breakpoint.smAndUp">
            <Dropdown/>
            <v-btn x-small @click="$vuetify.theme.dark = !$vuetify.theme.dark">
                {{$vuetify.theme.dark ? $t('header.switchToLight') : $t('header.switchToDark')}} <v-icon>palette</v-icon>
            </v-btn>
        </div>

        <!-- Mobile Menu Bottom -->
        <div class="ml-3" v-if="$vuetify.breakpoint.xs">
            <v-icon @click="openMobileMenu">keyboard_arrow_up</v-icon>
            <v-bottom-sheet v-model="mobileBottomMenu" v-if="mobileBottomMenu">
                <v-sheet class="pa-4 d-flex align-center justify-space-between" height="80px">
                    <Dropdown/>
                    <v-btn @click="$vuetify.theme.dark = !$vuetify.theme.dark">
                        {{$vuetify.theme.dark ? $t('header.switchToLight') : $t('header.switchToDark')}} <v-icon>palette</v-icon>
                    </v-btn>
                </v-sheet>
            </v-bottom-sheet>
        </div>
        <!-- Mobile Menu Bottom -->

        <div>
            <v-btn x-small v-for="(item, index) in navbar" :key="index" :value="item.name" :to="{ path: item.to }">
                <span v-if="item.name === 'Dashboard'">{{ $t('header.dashboard') }}</span>
                <span v-if="item.name === 'Extra'">{{ $t('header.extra') }}</span>
                <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
            <v-btn x-small @click="logout()">
                {{ $t('header.logout') }} <v-icon>power_settings_new</v-icon>
            </v-btn>
        </div>
        
    </v-bottom-navigation>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown from "../components/Languagedropdown";
// import Rating from "../components/Rating";

export default {
    components: { Dropdown },
    data: () => ({
        navbar: [
            { name: 'Dashboard', to: '/dashboard', icon: 'dashboard' },
            { name: 'Extra', to: '/extra', icon: 'settings' }
        ],
        langs: ['en', 'de'],
        slider: null,
        mobileBottomMenu: false,
    }),
    methods: {
        logout() {
            localStorage.removeItem('token');
            this.$router.push({ path: '/' });
        },
        openMobileMenu() {
            this.mobileBottomMenu = !this.mobileBottomMenu;
        }
    }
}
</script>

<style scoped>

</style>