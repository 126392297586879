<template>
    <v-main>
        <loader v-once/>
        <v-container fluid>
            <router-view />
        </v-container>
        <Header v-once/>
    </v-main>
</template>

<script>
import Header from "../components/Header.vue";
import Loader from '../components/Loader.vue';

export default {
    components: { 
        Header, 
        Loader 
    },
};
</script>

<style scoped>
    #app {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        height: 100vh
    }
</style>
